






































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonLink extends VueBaseActionButtonWidget {
  @Prop({
    required: false,
    default: null,
  })
  private readonly icon!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly label!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  private get text(): string {
    return `${this.$t(this.label)}`;
  }

  private get isDisplayed(): boolean {
    return !!((this.actionType === this.ActionButtonType.LINK
            || this.actionType === this.ActionButtonType.URL
            || this.actionType === this.ActionButtonType.LARGE_PRODUCT_URL)
        && this.label
        && this.route);
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private handleBaseUrl(): void {
    let { route } = this;
    if (route) {
      const external = /^((http(s)?:)?\/\/)/.test(this.route);
      if (!external) {
        route = `//${route}`;
      }

      const log = (categories: StatLoggerCategories, actions: StatLoggerActions) => (
        (url: string) => this.logStats(categories, actions, url)
      );

      RouteHelper.universalNavigation(
        route,
        this.$route.params,
        this.$router,
        undefined,
        log(StatLoggerCategories.LINK, StatLoggerActions.ADD),
      );
    }
  }
}
